<template>
  <div>
    <div style="margin-bottom: 20px">
      <el-form ref="form" :model="form" label-width="80px">
        <el-row :gutter="10">
          <el-col :span="8">
            <el-form-item label="商品名称">
              <el-select v-model="form.spuName" placeholder="请选择" style="width: 100%">
                <el-option
                    v-for="item in selectData"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <el-button type="primary" @click="onSearch">搜索</el-button>
              <el-button @click="onCancel">取消</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <el-table :data="list" border :max-height="maxHeight">
      <el-table-column prop="id" label="订单ID" fixed="left" width="150"></el-table-column>
      <el-table-column prop="spuId" label="商品ID" width="150"></el-table-column>
      <el-table-column prop="spuName" label="商品名称" width="150"></el-table-column>
      <el-table-column prop="logoUrl" label="商品封面" align="center" width="150">
        <template slot-scope="scope">
          <el-image :src="scope.row.logoUrl" fit="contain" style="width: 120px"/>
        </template>
      </el-table-column>
      <el-table-column prop="buyer.nickname" label="买家名称" width="120"></el-table-column>
      <el-table-column prop="buyer.phone" label="买家电话" width="120"></el-table-column>
      <el-table-column prop="totalAmount" label="订单金额">
        <template slot-scope="scope"> ￥{{ scope.row.totalAmount / 100 }}</template>
      </el-table-column>
      <el-table-column prop="statusDes" label="订单状态"></el-table-column>
      <el-table-column prop="createTime" label="创建时间" width="180">
        <template slot-scope="scope"> {{ formatTime(scope.row.createTime) }}</template>
      </el-table-column>
      <el-table-column label="操作" align="center" fixed="right">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="detail(scope.row)">详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        background
        layout="prev, pager, next"
        :total="totalRows"
        :current-page="page"
        :page-size="10"
        style="margin-top: 20px"
        @current-change="handleChangePage"
    >
    </el-pagination>
  </div>
</template>

<script>
import OrderApi from "../../request/OrderApi";
import dayjs from "dayjs";

export default {
  data() {
    return {
      maxHeight: document.documentElement.clientHeight - 200,
      page: 1,
      list: [],
      backList: [],
      searchList: [],
      form: {
        spuName: undefined,
      },
      totalRows: 0,
      selectData: [],
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      OrderApi.list({
        orderRoleType: "SELLER",
        pageNo: this.page,
        pageSize: 1000,
        spuType: "COURSE_GOODS",
      }).then((res) => {
        if (res.success) {
          this.backList = res.data;
          this.totalRows = res.data.length;
          this.list = res.data.slice(0, 10);
          const spuNames = new Set();
          res.data.forEach(item => spuNames.add(item.spuName));
          this.selectData = [...spuNames].map(item => {
            return {
              label: item,
              value: item,
            }
          })
        }
      });
    },

    handleChangePage(val) {
      this.page = val;
      if (this.form.spuName) {
        this.list = this.searchList.slice((val - 1) * 10, 10 * val);
      } else {
        this.list = this.backList.slice((val - 1) * 10, 10 * val);
      }
    },

    detail(val) {
      this.$router.push({
        path: "/order/detail",
        query: {
          id: val.id
        }
      })
    },
    formatTime(val) {
      return dayjs(val).format("YYYY-MM-DD HH:mm:ss");
    },
    async onCancel() {
      this.form.spuName = undefined;
      await this.loadData();
    },
    async onSearch() {
      this.searchList = this.backList.filter(item => item.spuName === this.form.spuName);
      this.totalRows = this.searchList.length;
      this.list = this.searchList;
    }
  },
};
</script>

<style></style>
